.test {
    font-size: xx-large;
    text-align: center;
    color: dimgrey;
    font-weight: bold;
}

.sup-desc {
    font-size: medium;
    text-align: center;
    margin-top: 15px;
}

.loginContainer {
    min-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../images/bg.png');
    background-color: black;
}

/* Registration form2  */
#otp {
    animation: left-to-right 1s ease-out forwards 1;
}

@keyframes left-to-right {
    0% {
        transform: translateX(-80px);
    }
}

@keyframes bottom-to-top {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

#backBtn {
    animation: animacii 1s;
}

@keyframes animacii {
    0% {
        transform: translateX(700px);
    }
}

/* Registration form 2  */
.skip {
    animation: right-to-left 1s ease-in-out forwards;
}

@keyframes right-to-left {
    100% {
        transform: translateX(-40px);
    }
}

#form1 {
    animation: fadeIn 2s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

#buttonAnimateLogin {
    animation: buttonAnimation 1s ease 0s normal none;
}

@keyframes buttonAnimation {
    0.0% {
        transform-origin: bottom left;
        transform: rotate(0) translate(60px, 80px);
        right: 100%;
    }
}

#otpAnimate {
    animation: otpAnimation 1s ease 0s normal none;
}

@keyframes otpAnimation {
    0.0% {
        transform-origin: bottom bottom;
        transform: rotate(0) translate(25px, 60px);
        right: 0%;
    }
}